import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  Segment,
  Header,
  Menu,
  Label,
  Grid,
  Table,
  Loader,
  Progress,
} from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import { format as dateFormat, parseISO } from 'date-fns'
import { format } from 'd3-format'

import {
  getProductLabel,
  getLabel,
  displayProductCost,
  getUserRole,
  COLORS,
} from '../../utils'
import {
  displayUnit,
  secondaryUnits,
  getSalePriceUnit,
  convertUnits,
} from '../../common/unit-utils'
import { productCost, productIngredientCost } from '../../common/unit-utils'
import { copyProduct } from './EditProduct'
import { queryStocks } from '../../actions/stocks'
import { queryProducts, productUsage } from '../../actions/products'
import RoleFilter from '../common/RoleFilter'
import NotFound from '../common/NotFound'
import { Role } from '../../common/roles'

const Product = () => {
  const params = useParams()
  const resources = useSelector((state) => state.resources.items)
  const recipes = useSelector((state) => state.recipes.items)
  const stocks = useSelector((state) => state.stocks.items)
  const companies = useSelector((state) => state.companies.items)
  const products = useSelector((state) => state.products.items)
  const usage = useSelector((state) => state.products.usage)
  const { username, role } = useSelector((state) => ({
    username: state.auth.user.username,
    role: getUserRole(state.auth),
  }))
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false)

  const product = products[params.id]

  useEffect(() => {
    if (!product) {
      // TODO
      return
    }

    // load missing ingredients
    const missingStockIds = _.chain(product.ingredients)
      .map('stockId')
      .filter((id) => id && !stocks[id])
      .value()
    if (!_.isEmpty(missingStockIds)) {
      dispatch(queryStocks({ ids: missingStockIds.join() }, { cache: true }))
    }

    const missingProductIds = _.chain(product.ingredients)
      .map('productId')
      .filter((id) => id && !products[id])
      .value()
    if (!_.isEmpty(missingProductIds)) {
      dispatch(
        queryProducts({ ids: missingProductIds.join() }, { cache: true })
      )
    }
  })

  useEffect(() => {
    if (product) {
      dispatch(productUsage(product.id))
    }
  }, [product, dispatch])

  if (!product) {
    return <NotFound message='Няма такъв продукт' />
  }

  const {
    id,
    name,
    recipeId,
    desc,
    producedOn,
    ingredients,
    qty,
    curQty,
    unit,
    byProduct,
    createdBy,
    duration,
    salePrice,
  } = product

  const totalCost = productCost(ingredients, resources, stocks, products)
  const secCost = secondaryUnits({ cost: totalCost, qty, unit })
  const recipe = recipeId && recipes[recipeId]
  const byProductStock = byProduct && stocks[byProduct.stockId]
  const byProductResource = byProductStock && resources[byProductStock.resource]

  const expiry = _.isNil(duration)
    ? null
    : moment(producedOn).add(duration, 'day')

  const canEdit =
    role === Role.ADMIN || _.isEqual(username, _.get(createdBy, 'username'))
  return (
    <>
      <Helmet title={product.name} />

      <Menu
        attached='top'
        inverted
        color={COLORS.PRODUCT}
        borderless
        className='wrap'
      >
        <Menu.Item header content={name} />
        <Menu.Menu position='right'>
          <Menu.Item
            icon='copy'
            title='Копирай'
            as={Link}
            to={'/products/new'}
            state={copyProduct(product, stocks)}
          />
          {canEdit && (
            <Menu.Item
              icon='edit'
              title='Редактирай'
              as={Link}
              to={`/products/${id}/edit`}
            />
          )}
        </Menu.Menu>
      </Menu>

      <Segment attached>
        <Label
          color={COLORS.PRODUCT}
          style={{ opacity: product.curQty > 0 ? 1 : 0.5 }}
          ribbon
          content={getProductLabel(product)}
          size='big'
        />
        <Grid doubling columns={5} padded='vertically'>
          <Grid.Row>
            <Grid.Column>
              <Header size='medium' content={name} subheader='наименование' />
            </Grid.Column>

            <Grid.Column>
              <Header
                size='medium'
                content={
                  recipe ? (
                    <Link to={`/recipes/${recipe.id}`}>{recipe.name}</Link>
                  ) : (
                    '~'
                  )
                }
                subheader='рецепта'
              />
            </Grid.Column>

            <Grid.Column>
              <Header
                size='medium'
                content={moment(producedOn).format('DD/MM/YY HH:mm')}
                subheader='приготвено на'
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={_.get(createdBy, 'username')}
                subheader='приготвил'
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                color={expiry && expiry.isBefore() ? 'red' : null}
                content={expiry ? expiry.format('DD/MM/YY') : '~'}
                subheader='годно до'
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Progress
                value={curQty}
                total={qty}
                indicating
                progress
                precision={1}
                label={`${format('.3~f')(curQty)} ${displayUnit(unit)}`}
                error={curQty < 0}
              />
            </Grid.Column>

            <Grid.Column>
              <Header
                size='medium'
                content={`${format('.3~f')(qty)} ${displayUnit(unit)}`}
                subheader='количество'
              />
            </Grid.Column>

            <Grid.Column>
              <Header
                content={
                  _.isNil(salePrice)
                    ? '~'
                    : `${format('.2f')(salePrice)} лв. / ${displayUnit(
                        getSalePriceUnit(unit)
                      )}`
                }
                subheader='продажна цена'
              />
            </Grid.Column>

            <RoleFilter>
              <Grid.Column>
                <Header
                  size='medium'
                  content={displayProductCost(totalCost, ' лв.')}
                  subheader='стойност'
                />
              </Grid.Column>

              <Grid.Column>
                <Header
                  size='medium'
                  content={displayProductCost(secCost.cost, ' лв.')}
                  subheader={`стойност за ${secCost.qty} ${displayUnit(
                    secCost.unit
                  )}`}
                />
              </Grid.Column>
            </RoleFilter>
          </Grid.Row>
        </Grid>

        {byProductStock ? (
          <Segment size='small' secondary>
            <Header content='Вторичен продукт' />
            <Grid doubling columns={4}>
              <Grid.Row>
                <Grid.Column>
                  <Label
                    color={COLORS.STOCK}
                    style={{ opacity: byProductStock.curQty > 0 ? 1 : 0.5 }}
                    as={Link}
                    to={`/stocks/${byProductStock.id}`}
                    tag
                    content={getLabel(byProductStock)}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Link to={`/resources/${byProductResource.id}`}>
                    {byProductResource.name}
                  </Link>
                </Grid.Column>
                <Grid.Column>
                  {byProductStock.quantity} {displayUnit(byProductStock.unit)}
                </Grid.Column>
                <RoleFilter>
                  <Grid.Column>
                    {format('.2f')(byProductStock.price)} лв.
                  </Grid.Column>
                </RoleFilter>
              </Grid.Row>
            </Grid>
          </Segment>
        ) : null}

        <Menu secondary pointing size='large'>
          <Menu.Item header active>
            Съставки
          </Menu.Item>
          <Menu.Item
            icon={expanded ? 'angle double down' : 'angle double right'}
            title={expanded ? 'Затвори всичко' : 'Отвори всичко'}
            onClick={() => setExpanded(!expanded)}
          />
        </Menu>

        <Ingredients ingredients={ingredients} expanded={expanded} />

        {desc && (
          <>
            <Header content='Описание' />
            {_.map(_.split(desc, '\n'), (p, index) => (
              <span key={index}>
                {p}
                <br />
              </span>
            ))}
          </>
        )}
      </Segment>
      <Menu secondary pointing>
        <Menu.Item active>История</Menu.Item>
      </Menu>
      <div className='y-scrollable'>
        <Table selectable compact unstackable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>дата</Table.HeaderCell>
              <Table.HeaderCell>тип</Table.HeaderCell>
              <Table.HeaderCell>име / вид</Table.HeaderCell>
              <Table.HeaderCell>фирма</Table.HeaderCell>
              <Table.HeaderCell>бележки</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>цена</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>преди</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>Δ</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>след</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {usage.status.isFetching && (
              <Table.Row>
                <Table.Cell colSpan={2}>
                  <Segment basic padded>
                    <Loader active />
                  </Segment>
                </Table.Cell>
              </Table.Row>
            )}
            {!usage.status.isFetching &&
              _.map(usage.log, (entry, index) => {
                const { before, after, diff } = entry
                const date = entry.producedOn || entry.soldOn || entry.revisedAt

                return (
                  <Table.Row key={index}>
                    <Table.Cell>
                      {dateFormat(parseISO(date), 'dd/MM/yy')}
                    </Table.Cell>

                    {entry.producedOn && (
                      <Table.Cell>
                        <Label
                          size='small'
                          color={COLORS.PRODUCT}
                          style={{ opacity: entry.curQty > 0 ? 1 : 0.5 }}
                          tag
                          as={Link}
                          to={`/products/${entry.id}`}
                          content={getProductLabel(entry)}
                        />
                      </Table.Cell>
                    )}
                    {entry.soldOn && (
                      <Table.Cell>
                        <Link to={`/sales/${entry.id}`}>продажба</Link>
                      </Table.Cell>
                    )}
                    {entry.revisedAt && (
                      <Table.Cell>
                        <Link to={`/revisions/${entry.id}`}>ревизия</Link>
                      </Table.Cell>
                    )}

                    <Table.Cell>{entry.name || entry.type}</Table.Cell>
                    <Table.Cell>
                      <Link to={`/companies/${entry.buyer}`}>
                        {_.get(companies, [entry.buyer, 'name'])}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{_.truncate(entry.notes)}</Table.Cell>

                    <Table.Cell textAlign='right'>
                      {entry.soldOn && `${format('.2f')(entry.price)} лв.`}
                    </Table.Cell>
                    <Table.Cell textAlign='right'>
                      {format('.3f')(before)}
                    </Table.Cell>
                    <Table.Cell textAlign='right'>
                      {format('.3f')(diff)}
                    </Table.Cell>
                    <Table.Cell textAlign='right' negative={after < 0}>
                      {format('.3f')(after)}
                    </Table.Cell>
                    <Table.Cell>{displayUnit(product.unit)}</Table.Cell>
                  </Table.Row>
                )
              })}
          </Table.Body>
        </Table>
      </div>
    </>
  )
}

const expandIngredients = (ingredients, products, qtyRatio = 1) =>
  _.flatten(
    _.map(ingredients, (ingredient) => {
      const { productId } = ingredient
      if (productId) {
        const product = products[productId]
        const ingredientQty = ingredient.qty * qtyRatio
        const unitRatio = convertUnits(ingredient.unit, product.unit)
        const newQtyRatio = (ingredientQty * unitRatio) / product.qty

        return expandIngredients(
          products[productId].ingredients,
          products,
          newQtyRatio
        )
      }
      return { ...ingredient, qty: ingredient.qty * qtyRatio }
    })
  )

const aggregateStockIngredients = (ingredients, resources, stocks) => {
  return _.map(_.groupBy(ingredients, 'stockId'), (stockGroup, stockId) => {
    const stock = stocks[stockId]
    const resource = resources[stock.resource]
    const totalQty = _.sumBy(stockGroup, ({ qty, unit }) => {
      const unitRatio = convertUnits(
        unit,
        resource.unit,
        resource.density,
        resource.densityUnit
      )
      return qty * unitRatio
    })
    return { stockId, qty: totalQty, unit: resource.unit }
  })
}

const Ingredients = ({ ingredients, expanded }) => {
  const resources = useSelector((state) => state.resources.items)
  const stocks = useSelector((state) => state.stocks.items)
  const products = useSelector((state) => state.products.items)
  const companies = useSelector((state) => state.companies.items)

  if (expanded) {
    const expandedIngredients = expandIngredients(ingredients, products)
    ingredients = aggregateStockIngredients(
      expandedIngredients,
      resources,
      stocks
    )
  }

  return (
    <div className='y-scrollable'>
      <Table
        compact
        size='small'
        selectable
        basic='very'
        unstackable
        singleLine
      >
        <Table.Body>
          {_.map(ingredients, (ingredient, index) => {
            const { stockId, productId, qty, unit } = ingredient
            const cost = productIngredientCost(
              ingredient,
              resources,
              stocks,
              products
            )
            if (stockId) {
              const stock = stocks[stockId]
              if (!stock) {
                return (
                  <Table.Row key={index}>
                    <Table.Cell colSpan={5} textAlign='center'>
                      <Loader inline active size='mini' />
                    </Table.Cell>
                  </Table.Row>
                )
              }
              const resource = resources[stock.resource]

              return (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Label
                      color={COLORS.STOCK}
                      style={{ opacity: stock.curQty > 0 ? 1 : 0.5 }}
                      as={Link}
                      to={`/stocks/${stock.id}`}
                      tag
                      content={getLabel(stock)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/resources/${resource.id}`}>
                      {resource.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{stock.batchId || getLabel(stock)}</Table.Cell>
                  <Table.Cell>
                    <Link to={`/companies/${stock.supplier}`}>
                      {companies[stock.supplier]?.companyName}
                    </Link>
                  </Table.Cell>
                  <Table.Cell textAlign='right'>
                    {format('.3~f')(qty)}
                  </Table.Cell>
                  <Table.Cell textAlign='left' style={{ paddingLeft: 0 }}>
                    {displayUnit(unit)}
                  </Table.Cell>

                  <RoleFilter>
                    <Table.Cell>{displayProductCost(cost, ' лв.')}</Table.Cell>
                  </RoleFilter>
                </Table.Row>
              )
            }
            if (productId) {
              const product = products[productId]
              if (!product) {
                return (
                  <Table.Row>
                    <Table.Cell colSpan={5} textAlign='center'>
                      <Loader inline active size='mini' />
                    </Table.Cell>
                  </Table.Row>
                )
              }

              return (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Label
                      color={COLORS.PRODUCT}
                      style={{ opacity: product.curQty > 0 ? 1 : 0.5 }}
                      as={Link}
                      to={`/products/${product.id}`}
                      tag
                      content={getProductLabel(product)}
                    />
                  </Table.Cell>
                  <Table.Cell>{product.name}</Table.Cell>
                  <Table.Cell>{getProductLabel(product)}</Table.Cell>
                  <Table.Cell />
                  <Table.Cell textAlign='right'>{qty}</Table.Cell>
                  <Table.Cell textAlign='left' style={{ paddingLeft: 0 }}>
                    {displayUnit(unit)}
                  </Table.Cell>

                  <RoleFilter>
                    <Table.Cell>{displayProductCost(cost, ' лв.')}</Table.Cell>
                  </RoleFilter>
                </Table.Row>
              )
            }
          })}
        </Table.Body>
      </Table>
    </div>
  )
}

export default Product
